import { Fragment, useState, useEffect } from "react";
// import about from "../img/wav.jpg";
import OwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import wav1 from "../img/clay1.jpg";
import wav2 from "../img/wick1.jpg";
import wav3 from "../img/wick3.jpg";

import "./care.scss";

const DaviesCourt = () => {
  const options = {
    loop: true,
    margin: 45,
    items: 2,
    autoplay: true,
    nav: true,
  };
  const [isMobile, setIsMobile] = useState(false);

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 990) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    if (window.performance) {
      handleResize();

      if (performance.navigation.type == 1) {
        //     window.addEventListener("beforeunload", alertUser);
        return () => {
          window.addEventListener("resize", handleResize);

          //         window.removeEventListener("beforeunload", alertUser);
        };
      }
    }
  }, []);
  return (
    <Fragment>
      <div
        style={{
          position: "relative",
          top: !isMobile ? "100px" : "0px",
        }}
        class="container-fluid bg-primary py-5  mb-5"
        className="chb"
      >
        <div class="row py-3">
          <div class="col-12 text-center">
            <h1
              style={{
                marginTop: "90px",
              }}
              class="display-3 text-white animated zoomIn"
            >
              {" "}
              St David's Court{" "}
            </h1>
            <a href="" class="h4 text-white">
              Home
            </a>
            <i class="far fa-circle text-white px-2"></i>
            <a href="" class="h4 text-white">
              St David's Court{" "}
            </a>
          </div>
        </div>
      </div>

      <div
        style={{
          position: "relative",
          top: !isMobile ? "100px" : "0px",
          marginBottom: !isMobile ? "100px" : "50px",
        }}
        className="container-fluid py-5 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        {/* start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container">
            {/* cont1 */}
            <div className="row g-5">
              <div className="col-lg-5">
                <div className="section-title mb-4">
                  <h5 className="position-relative d-inline-block text-primary text-uppercase">
                    St David's Court{" "}
                  </h5>
                </div>
                <p className="mb-4">
                  St. David’s Court offers a supportive living environment with
                  a mix of one- and two-bedroom flats, designed to accommodate
                  up to 21 service users with complex mental health needs,
                  autism, and learning disabilities. Our dedicated team provides
                  24/7, year-round support, ensuring the safety and well-being
                  of all residents. A management team is on-site Monday to
                  Friday, maintaining an open-door policy to offer guidance and
                  assistance whenever needed. Key features of St. David’s Court
                  include:
                  <br />
                  <br />
                  <li>
                    24/7 staffing, 365 days a year to provide high-quality care
                    and support.
                  </li> <br />
                  <li>
                    On-site management presence (Monday to Friday) for immediate
                    assistance.
                  </li> <br />
                  <li>
                    Free parking for service users, visitors, and professionals.
                  </li> <br />
                  <li>
                    A welcoming communal area for activities, meetings, and
                    social interaction.
                  </li> <br />
                  <li>
                    Convenient location on the outskirts of Pitsea, Essex, with
                    easy access to all local amenities.
                  </li> <br />
                  <li>
                    sExcellent transport links, ensuring connectivity for
                    service users and visitors.
                  </li> <br />
                  At St. David’s Court, we strive to create a safe, inclusive,
                  and engaging community where individuals receive the care and
                  support they need to thrive.
                </p>
                <h5
                  className="text-uppercase text-primary wow fadeInUp"
                  data-wow-delay="0.3s"
                >
                  Call for Appointment
                </h5>
                <h1 className="wow fadeInUp" data-wow-delay="0.6s">
                  01268 988500{" "}
                </h1>
              </div>

              <div className="col-lg-7">
                <OwlCarousel
                  className="owl-theme"
                  {...options}
                  style={{
                    navText: [
                      '<i class="bi bi-arrow-left"></i>',
                      '<i class="bi bi-arrow-right"></i>',
                    ],
                    responsive: {
                      0: {
                        items: 1,
                      },
                      768: {
                        items: 2,
                      },
                    },
                  }}
                >
                  <div className="price-item pb-4">
                    <a href="#SLive">
                      <div className="position-relative">
                        <img
                          className="img-fluid rounded-top"
                          src={wav1}
                          alt=""
                        />
                      </div>
                    </a>
                  </div>
                  <div className="price-item pb-4">
                    <a href="#SLive">
                      <div className="position-relative">
                        <img
                          className="img-fluid rounded-top"
                          src={wav3}
                          alt=""
                        />
                      </div>
                    </a>
                  </div>
                  <div className="price-item pb-4">
                    <div className="position-relative">
                      <img
                        className="img-fluid rounded-top"
                        src={wav2}
                        alt=""
                      />
                    </div>
                  </div>
                </OwlCarousel>
              </div>
            </div>

            {/* cont2 */}
            <div style={{ marginTop: "20px" }} className="row g-5">
              <div className="col-lg-5">
                <div className="section-title mb-4">
                  <h5 className="position-relative d-inline-block text-primary text-uppercase">
                    Registered Manager{" "}
                  </h5>
                </div>
                <img
                  style={{ height: "300px" }}
                  src={require("../img/team-1.jpeg")}
                />
                <h2
                  style={{ marginTop: "20px" }}
                  className="wow fadeInUp"
                  data-wow-delay="0.6s"
                >
                  {/* Mr Kaveerajsing Baungally{" "} */}
                </h2>
              </div>
              <div className="col-lg-5">
                <h5
                  className="text-uppercase text-primary wow fadeInUp"
                  data-wow-delay="0.3s"
                >
                  Call for Appointment
                </h5>
                <h1 className="wow fadeInUp" data-wow-delay="0.6s">
                  Rosie Kavanagh
                </h1>
                <h5
                  className="text-uppercase text-primary wow fadeInUp"
                  data-wow-delay="0.3s"
                >
                  {" "}
                  01268 988500{" "}
                </h5>
                <p>
                  I serve as the Registered Manager at Excellence Care. My name
                  is Rosie Kavanagh , and I am available for contact via phone
                  at <b>01268 988500</b> or through email at{" "}
                  <b>info@excellence-care.co.uk</b> Feel free to reach out to me
                  for any inquiries or assistance related to our care services.
                </p>
                <Link
                  to="/contact"
                  className="btn btn-primary py-3 px-5 mt-4 wow zoomIn"
                  data-wow-delay="0.6s"
                >
                  Make Appointment
                </Link>
                <a
                  style={{
                    backgroundColor: "#fff",
                    color: "black",
                    marginLeft: "15px",
                    border: "1px solid green",
                  }}
                  className="btn  btn-primary  py-3 px-5 mt-4 wow zoomIn"
                  href="https://api.whatsapp.com/send?phone=447402951877&text=Hello%20Excellence%20Care!"
                >
                  WhatsApp
                  <i
                    style={{
                      color: "green",
                      marginLeft: "10px",
                      fontSize: "20px",
                    }}
                    className="fab fa-whatsapp fw-normal"
                  ></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DaviesCourt;
