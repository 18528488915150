import React, { Fragment, useState } from "react";
import logouk from "./img/logouk.png";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Row, Col, NavDropdown, Container, Navbar, Nav } from "react-bootstrap";
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";
//import "react-bootstrap-submenu/dist/index.css";

const Head = () => {
  const [drop1, setDrop1] = useState(false);
  const [dropValue, setDropValue] = useState("");
  const [open, setOpen] = useState(false);
  return (
    <Fragment>
      <Navbar bg="light" expand="lg" variant="light">
        <Container>
          <Navbar.Brand>
            <Link to="/">
              <img style={{ height: "80px" }} src={logouk} alt="logo" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => {
              setTimeout(() => {
                setOpen(true);
              }, 50);
            }}
          />
        </Container>
        <div
          style={{
            flexBasis: "100%",
            display: "flex",
            position: "relative",
            zIndex: 2,
          }}
        >
          <div
            style={{
              // padding: "5px 0",
              flexBasis: "50%",
              textAlign: "center",
              // width: "50%"
            }}
          >
            {/* <Link
              onClick={() => {
                setOpen(false);
              }}
              to="/"
              style={{ marginTop: "20px" }}
              className=""
            >
              Looking for care?👉{" "}
            </Link> */}
            <h5
              style={{ marginLeft: "",marginTop:'8px' ,fontSize:"17px"}}
              className=" d-inline-block text-primary"
            >
              Looking for Care?
            </h5>
          </div>
          <div
            style={{
              //padding: "5px 0",
              flexBasis: "50%",
              textAlign: "center",
            }}
          >
            <a
              href="tel:012689 88500"
              style={{ backgroundColor: "#F28C28" }}
              className="btn btn-primary"
            >
              Call: 012689 88500{" "}
            </a>
          </div>
        </div>

        {open ? (
          <Navbar.Collapse id="basic-navbar-nav" style={{ marginLeft: "20px" }}>
            <Nav className="me-auto">
              {!drop1 && (
                <Nav.Link
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <Link style={{ color: "black" }} to="/">
                    Home
                  </Link>
                </Nav.Link>
              )}
              {/* about */}
              <Nav.Link
                onClick={() => {
                  setDropValue("About");
                  if (drop1) setDrop1(false);
                  else setDrop1(true);
                }}
              >
                {" "}
                <NavDropdownMenu title="About Us" id="collasible-nav-dropdown">
                  <NavDropdown.Item href="#action/3.1">
                    {" "}
                    <Link
                      onClick={() => {
                        setOpen(false);
                      }}
                      to="/excellencecarevision"
                    >
                      {" "}
                      Our Vision & Mission{" "}
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.1">
                    <Link
                      onClick={() => {
                        setOpen(false);
                      }}
                      to="/aboutus"
                    >
                      Our Journey{" "}
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.1">
                    <Link
                      onClick={() => {
                        setOpen(false);
                      }}
                      to="/awards"
                    >
                      {" "}
                      Our Awards
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.1">
                    <Link
                      onClick={() => {
                        setOpen(false);
                      }}
                      to="/team"
                    >
                      Our Team{" "}
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.1">
                    <Link
                      onClick={() => {
                        setOpen(false);
                      }}
                      to="/welcomingdiversity"
                    >
                      {" "}
                      We Encourage Diversity{" "}
                    </Link>
                  </NavDropdown.Item>

                  <NavDropdown.Item href="#action/3.1">
                    <Link
                      onClick={() => {
                        setOpen(false);
                      }}
                      to="/#testimonial"
                    >
                      {" "}
                      Testimonials
                    </Link>
                  </NavDropdown.Item>
                </NavDropdownMenu>
              </Nav.Link>
              {/* care services */}
              <Nav.Link
                onClick={() => {
                  setDropValue("Care Services");
                  if (drop1) setDrop1(false);
                  else setDrop1(true);
                }}
              >
                {" "}
                <NavDropdownMenu
                  title="Care Services"
                  id="collasible-nav-dropdown"
                >
                  <NavDropdown.Item href="#action/3.1">
                    <Link
                      onClick={() => {
                        setOpen(false);
                      }}
                      to="/careservice"
                    >
                      Care Services{" "}
                    </Link>
                  </NavDropdown.Item>
                  {/* 1 */}
                  <DropdownSubmenu href="#action/3.7" title="Supported Living">
                    <NavDropdown.Item href="#action/8.1">
                      <Link
                        onClick={() => {
                          setOpen(false);
                        }}
                        to="/autism"
                      >
                        {" "}
                        Autism{" "}
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/8.1">
                      <Link
                        onClick={() => {
                          setOpen(false);
                        }}
                        to="/learningdisability"
                      >
                        Learning Disability
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/8.1">
                      <Link
                        onClick={() => {
                          setOpen(false);
                        }}
                        to="/pmld"
                      >
                        {" "}
                        Profound and Multiple Learning Disability (PMLD){" "}
                      </Link>
                    </NavDropdown.Item>
                  </DropdownSubmenu>
                  {/* 2 */}
                  <DropdownSubmenu href="#action/3.7" title="Home Care">
                    <NavDropdown.Item href="#action/8.1">
                      <Link
                        onClick={() => {
                          setOpen(false);
                        }}
                        to="/domiciliarycare"
                      >
                        {" "}
                        What is Home care / Domiciliary Care?
                      </Link>
                    </NavDropdown.Item>
                  </DropdownSubmenu>
                  {/* 3 */}
                  <DropdownSubmenu href="#action/3.7" title="Residential">
                    <NavDropdown.Item href="#action/8.1">
                      <Link
                        onClick={() => {
                          setOpen(false);
                        }}
                        to="/residentialcare"
                      >
                        {" "}
                        What is Residential Care?
                      </Link>
                    </NavDropdown.Item>
                  </DropdownSubmenu>
                </NavDropdownMenu>
              </Nav.Link>
              {/* useful information */}
              <Nav.Link
                onClick={() => {
                  setDropValue("Useful Information");
                  if (drop1) setDrop1(false);
                  else setDrop1(true);
                }}
              >
                {" "}
                <NavDropdownMenu
                  title="Useful Information"
                  id="collasible-nav-dropdown"
                >
                  <NavDropdown.Item href="#action/3.1">
                    {" "}
                    <Link
                      onClick={() => {
                        setOpen(false);
                      }}
                      to="/covid19updates"
                    >
                      Covid 19 updates{" "}
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.1">
                    <Link
                      onClick={() => {
                        setOpen(false);
                      }}
                      to="/blog"
                    >
                      Blog{" "}
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.1">
                    <Link
                      onClick={() => {
                        setOpen(false);
                      }}
                      to="/healthandsafety"
                    >
                      {" "}
                      Health & Safety
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.1">
                    <Link
                      onClick={() => {
                        setOpen(false);
                      }}
                      to="/laws"
                    >
                      {" "}
                      Laws{" "}
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.1">
                    <Link
                      onClick={() => {
                        setOpen(false);
                      }}
                      to="/preventingfalls"
                    >
                      {" "}
                      Preventing falls{" "}
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/8.1">
                    <Link
                      onClick={() => {
                        setOpen(false);
                      }}
                      to="/mais"
                    >
                      {" "}
                      Meeting the Accessible Information Standard
                    </Link>
                  </NavDropdown.Item>
                </NavDropdownMenu>
              </Nav.Link>
              {/* our care home */}
              <Nav.Link
                onClick={() => {
                  setDropValue("Care Services");
                  if (drop1) setDrop1(false);
                  else setDrop1(true);
                }}
              >
                {" "}
                <NavDropdownMenu title="Our Sites" id="collasible-nav-dropdown">
                  {/* 1 */}
                  <DropdownSubmenu href="#action/3.7" title="London">
                    <NavDropdown.Item href="#action/8.1">
                      <Link
                        onClick={() => {
                          setOpen(false);
                        }}
                        to="/waverleylodge"
                      >
                        {" "}
                        Waverly Lodge
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/8.1">
                      <Link
                        onClick={() => {
                          setOpen(false);
                        }}
                        to="/cranbrookmews"
                      >
                        Cranbrook Mews{" "}
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/8.1">
                      <Link
                        onClick={() => {
                          setOpen(false);
                        }}
                        to="/easternlodge"
                      >
                        {" "}
                        Eastern Lodge{" "}
                      </Link>
                    </NavDropdown.Item>
                  </DropdownSubmenu>
                  {/* 2 */}
                  <DropdownSubmenu href="#action/3.7" title="Essex">
                    <NavDropdown.Item href="#action/8.1">
                      <Link
                        onClick={() => {
                          setOpen(false);
                        }}
                        to="/graysdeck"
                      >
                        {" "}
                        Grays Decking
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/8.1">
                      <Link
                        onClick={() => {
                          setOpen(false);
                        }}
                        to="/wickford"
                      >
                        Wickford{" "}
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/8.1">
                      <Link
                        onClick={() => {
                          setOpen(false);
                        }}
                        to="/clayburnlodge"
                      >
                        {" "}
                        Clayburn lodge{" "}
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/8.1">
                      <Link
                        onClick={() => {
                          setOpen(false);
                        }}
                        to="/daviescourt"
                      >
                        {" "}
                        St David's Court{" "}
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/8.1">
                      <Link
                        onClick={() => {
                          setOpen(false);
                        }}
                        to="/benfleethouse"
                      >
                        {" "}
                        Benfleet House{" "}
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/8.1">
                      <Link
                        onClick={() => {
                          setOpen(false);
                        }}
                        to="/priors"
                      >
                        {" "}
                        Priors{" "}
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/8.1">
                      <Link
                        onClick={() => {
                          setOpen(false);
                        }}
                        to="/seaview"
                      >
                        {" "}
                        Sea View{" "}
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/8.1">
                      <Link
                        onClick={() => {
                          setOpen(false);
                        }}
                        to="/dewlands"
                      >
                        {" "}
                        Dewlands{" "}
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/8.1">
                      <Link
                        onClick={() => {
                          setOpen(false);
                        }}
                        to="/northcolne"
                      >
                        {" "}
                        North Colne
                      </Link>
                    </NavDropdown.Item>
                  </DropdownSubmenu>
                  {/* 3 */}
                  <DropdownSubmenu href="#action/3.7" title="Staffordshire">
                    <NavDropdown.Item href="#action/8.1">
                      <Link
                        onClick={() => {
                          setOpen(false);
                        }}
                        to="/domiciliarycare"
                      >
                        {" "}
                      </Link>
                    </NavDropdown.Item>
                  </DropdownSubmenu>
                </NavDropdownMenu>
              </Nav.Link>
              <Nav.Link
                onClick={() => {
                  setOpen(false);
                }}
              >
                <Link style={{ color: "black" }} to="/contact">
                  Contact
                </Link>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        ) : (
          " "
        )}
      </Navbar>
    </Fragment>
  );
};

export default Head;
